<template>
    <v-container
        class="d-flex flex-column"
        :style="{ height: viewHeight + 'px' }"
        v-resize="onResize"
    >
        <v-row v-if="currentPromo !== null && mounted">
            <v-col cols="12">
                <v-radio-group
                    row
                    v-model="sospeso"
                    :class="{ changed: isPropertyChanged('Sospesa') }"
                >
                    <v-radio label="Attiva" value="0"></v-radio>
                    <v-radio label="Sospesa" value="1"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="4">
                <v-card class="promoImage d-flex flex-column">
                    <v-row
                        :class="{ changed: isPropertyChanged('ObjectIdImage') }"
                    >
                        <v-col>
                            <img
                                class="promoImg"
                                v-if="hasImage"
                                :src="getImageFile"
                            />
                        </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                        <v-file-input
                            :label="btnImage"
                            show-size
                            accept=".png, .jpg, .jpeg"
                            small-chips
                            :rules="rules"
                            v-model="currentPromo.imageFile"
                            prepend-icon="mdi-camera"
                        >
                        </v-file-input>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" sm="7" md="8" lg="8">
                <PromoPrioritySelector
                    :model.sync="currentPromo.Priorita"
                    :class="{ changed: isPropertyChanged('Priorita') }"
                ></PromoPrioritySelector>

                <PromoCategorySelectorLocal
                    :categories="localCategories"
                    :model.sync="currentPromo.Type"
                    :class="{ changed: isPropertyChanged('Type') }"
                ></PromoCategorySelectorLocal>

                <v-text-field
                    outlined
                    v-model="currentPromo.Titolo"
                    label="Titolo"
                    :class="{ changed: isPropertyChanged('Titolo') }"
                    counter
                >
                </v-text-field>

                <v-textarea
                    outlined
                    v-model="currentPromo.Descrizione"
                    label="Testo Promo"
                    counter
                    rows="11"
                    :class="{ changed: isPropertyChanged('Descrizione') }"
                ></v-textarea>
            </v-col>
            <v-col cols="12">
                <v-switch
                    v-model="publishDateCustom"
                    :label="
                        publishDateCustom
                            ? 'Personalizza Pubblicazione'
                            : 'Personalizza Pubblicazione'
                    "
                ></v-switch>
            </v-col>

            <v-col
                cols="6"
                :class="{ changed: isPropertyChanged('InizioValidita') }"
            >
                <DateSelector
                    :formDates.sync="inizioValidita"
                    :label="'Inizio Validità'"
                ></DateSelector>
            </v-col>

            <v-col
                cols="6"
                :class="{ changed: isPropertyChanged('FineValidita') }"
            >
                <DateSelector
                    :formDates.sync="fineValidita"
                    :label="'Fine Validità'"
                ></DateSelector>
            </v-col>

            <v-col
                cols="6"
                :class="{ changed: isPropertyChanged('InizioPubblicazione') }"
            >
                <DateSelector
                    :formDates.sync="inizioPubblicazione"
                    :label="'Inizio Pubblicazione'"
                    :disabled="!publishDateCustom"
                ></DateSelector>
            </v-col>

            <v-col
                cols="6"
                :class="{ changed: isPropertyChanged('FinePubblicazione') }"
            >
                <DateSelector
                    :formDates.sync="finePubblicazione"
                    :label="'Fine Pubblicazione'"
                    :disabled="!publishDateCustom"
                ></DateSelector>
            </v-col>

            <v-col
                cols="12"
                sm="12"
                md="6"
                :class="{ changed: isPropertyChanged('ObjectIdAudio') }"
            >
                <v-row class="align-center px-4">
                    <v-file-input
                        :label="btnAudio"
                        show-size
                        prepend-icon="mdi-music"
                        accept="audio/mpeg"
                        small-chips
                        :rules="rules"
                        v-model="currentPromo.audioFile"
                    ></v-file-input>
                    <v-btn
                        class="ml-4"
                        :disabled="!hasAudio"
                        @click.stop="showMultimediaContent(0)"
                        ><v-icon left dark> mdi-play </v-icon>Play</v-btn
                    >
                </v-row>
            </v-col>

            <v-col
                cols="12"
                sm="12"
                md="6"
                :class="{ changed: isPropertyChanged('ObjectIdVideo') }"
            >
                <v-row class="align-center px-4">
                    <v-file-input
                        :label="btnVideo"
                        show-size
                        prepend-icon="mdi-video"
                        accept="video/mp4"
                        small-chips
                        :rules="rules"
                        v-model="currentPromo.videoFile"
                    ></v-file-input>

                    <v-btn
                        class="ml-4"
                        :disabled="!hasVideo"
                        @click.stop="showMultimediaContent(1)"
                        ><v-icon left dark> mdi-play </v-icon>Play</v-btn
                    >
                </v-row>
            </v-col>

            <v-col
                cols="12"
                sm="12"
                md="6"
                :class="{ changed: isPropertyChanged('ObjectIdAttachment') }"
            >
                <v-row class="align-center px-4">
                    <v-file-input
                        :label="btnAttachment"
                        show-size
                        accept=".png, .jpg, .jpeg, .pdf"
                        small-chips
                        :rules="rules"
                        v-model="currentPromo.attachFile"
                    >
                    </v-file-input>
                    <v-btn
                        class="ml-4"
                        :disabled="!hasAttachment"
                        @click.stop="showMultimediaContent(2)"
                    >
                        <v-icon left dark> mdi-eye </v-icon>
                        Visualizza
                    </v-btn>
                    <v-btn
                        class="ml-4"
                        :disabled="!hasAttachment || readOnly"
                        color="error"
                        @click.stop="removeAttachment"
                        icon
                    >
                        <v-icon left> mdi-delete </v-icon>
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="5">
                <v-card elevation="4">
                    <v-card-title>
                        {{ availableStores.length }} Negozi Disponibili
                    </v-card-title>
                    <v-text-field
                        v-model="availablesearch"
                        label="Punto Vendita"
                        append-icon="mdi-magnify"
                        class="mx-4"
                        filled
                    ></v-text-field>

                    <StoresDragList
                        :stores.sync="availableStores"
                        :search="availablesearch"
                        :height="'435'"
                        @currentSelection="availableChanged"
                    ></StoresDragList>
                </v-card>
            </v-col>
            <v-col cols="2" align-self="center">
                <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                            fab
                            icon
                            large
                            @click="removeAll"
                            :disabled="promoStores.length === 0"
                        >
                            <v-icon x-large>mdi-rewind</v-icon></v-btn
                        >
                    </v-col>
                    <v-col
                        cols="12"
                        class="d-flex justify-center"
                        :disabled="availableStores.length === 0"
                    >
                        <v-btn fab icon large @click="addAll">
                            <v-icon x-large>mdi-fast-forward</v-icon></v-btn
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="5">
                <v-card elevation="4">
                    <v-card-title>
                        {{ promoStores.length }} Negozi Applicati
                    </v-card-title>
                    <v-text-field
                        v-model="appliedsearch"
                        label="Punto Vendita"
                        append-icon="mdi-magnify"
                        class="mx-4"
                        filled
                    ></v-text-field>

                    <StoresDragList
                        :stores.sync="promoStores"
                        :search="appliedsearch"
                        :height="'435'"
                        @currentSelection="appliedChanged"
                    ></StoresDragList>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    color="error"
                    class="ml-2"
                    :disabled="!isChanged"
                    @click="revert"
                    >RIPRISTINA</v-btn
                >
                <v-btn
                    color="primary"
                    class="ml-2"
                    :disabled="!isChanged"
                    @click="save"
                    >SALVA</v-btn
                >
            </v-col>
        </v-row>
        <ShowContent
            v-if="showContent"
            @close="showContent = !showContent"
            :media="mediaObject"
        ></ShowContent>
    </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import * as promoCategoryActions from "../../store/actions/promoCategory";
import * as storeActions from "../../store/actions/store";
import * as promoActions from "../../store/actions/promo";
import * as multiFarmActions from "../../store/actions/multiFarmPromo";
import { checkObjectDiff } from "../../utils/utils";

export const DateSelector = () => import("../core/DateSelector");
export const ShowContent = () => import("../core/ShowContent");
// export const StoresTable = () => import("./PromoStores");
export const PromoCategorySelectorLocal = () =>
    import("../core/PromoCategorySelectorLocal");
export const PromoPrioritySelector = () =>
    import("../core/PromoPrioritySelector");

export const StoresDragList = () => import("../promo/PromoStoresDragList");

export default {
    props: ["promoId"],
    components: {
        DateSelector,
        ShowContent,
        PromoCategorySelectorLocal,
        PromoPrioritySelector,
        StoresDragList
    },
    data: () => ({
        currentPromo: null,
        resetModel: null,
        publishDateCustom: false,
        viewHeight: 0,
        propertiesChanged: [],
        localCategories: [],
        rules: [
            value =>
                !value ||
                value.size < 50000000 ||
                "La dimensione deve essere inferiore a 50 MB!"
        ],
        stores: [],
        availableStores: [],
        promoStores: [],
        appliedsearch: "",
        availablesearch: "",
        showContent: false,
        mediaObject: {},
        isChanged: false,
        currentAvailable: [],
        currentSelected: [],
        mounted: false
    }),
    watch: {
        publishDateCustom: {
            handler: function(val) {
                if (val === false && this.loaded) {
                    this.currentPromo.InizioPubblicazione = this.currentPromo.InizioValidita;
                    this.currentPromo.finePubblicazione = this.currentPromo.FineValidita;
                }
            },
            deep: true,
            immediate: true
        },
        currentPromo: {
            handler: function(val) {
                this.isChanged = checkObjectDiff(
                    this.currentPromo,
                    this.resetModel
                );
            },
            deep: true,
            immediate: true
        },
        promoStores: function(newVals, oldVals) {
            // console.log(this.mounted);
            if (this.mounted) {
                // console.log(newVals, oldVals);
                const stores = newVals.map(val => {
                    return val.IDCliente;
                });
                console.log(
                    "punti vendita prima",
                    this.currentPromo.PuntiVendita
                );
                if (stores.length !== this.currentPromo.PuntiVendita.length) {
                    this.currentPromo.PuntiVendita = stores;
                }
                // console.log(diffs)
                console.log(
                    "punti vendita dopo",
                    this.currentPromo.PuntiVendita
                );
                //this.$emit("update:stores", stores);
            }
        }
    },
    computed: {
        ...mapState({
            farms: state => state.farmModule.farms,
            multiFarmPromos: state => state.promoMultiFarmModule.promos
        }),
        filePath() {
            return "/files/fileidstream";
        },
        sospeso: {
            get: function() {
                return this.currentPromo.Sospeso === true ? "1" : "0";
            },
            set: function(newValue) {
                console.log(newValue, newValue === "1");
                this.currentPromo.Sospeso = newValue === "1" ? true : false;
                console.log(this.currentPromo.Sospeso);
            }
        },
        btnAudio() {
            return this.hasAudio ? "Sostituisci Audio" : "Carica Audio";
        },

        btnVideo() {
            return this.hasVideo ? "Sostituisci Video" : "Carica Video";
        },

        btnAttachment() {
            return this.hasAttachment
                ? "Sostituisci Allegato"
                : "Carica Allegato";
        },

        btnImage() {
            return this.hasImage ? "Sostituisci Immagine" : "Carica Immagine";
        },

        hasVideo() {
            return (
                this.currentPromo.VideoName !== null &&
                this.currentPromo.ObjectIdVideo.length > 0
            );
        },

        hasAudio() {
            return (
                this.currentPromo.AudioName !== null &&
                this.currentPromo.ObjectIdAudio.length > 0
            );
        },

        hasImage() {
            return (
                this.currentPromo.ImageName !== null &&
                this.currentPromo.ObjectIdImage.length > 0
            );
        },

        hasAttachment() {
            return (
                this.currentPromo.AttachmentName !== null &&
                this.currentPromo.ObjectIdAttachment.length > 0
            );
        },

        getAudioFile() {
            return (
                this.filePath +
                "/icoop_audio/" +
                this.currentPromo.ObjectIdAudio
            );
        },

        getImageFile() {
            return (
                this.filePath +
                "/icoop_image/" +
                this.currentPromo.ObjectIdImage
            );
        },

        getVideoFile() {
            return (
                this.filePath +
                "/icoop_video/" +
                this.currentPromo.ObjectIdVideo
            );
        },

        getAttachmentFile() {
            return (
                this.filePath +
                "/icoop_attachment/" +
                this.currentPromo.ObjectIdAttachment
            );
        },
        inizioValidita: {
            get() {
                return this.currentPromo.InizioValidita.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.InizioValidita = `${newValue}T00:00:00Z`;
                if (this.publishDateCustom === false) {
                    this.currentPromo.InizioPubblicazione = this.currentPromo.InizioValidita;
                }
            }
        },

        fineValidita: {
            get() {
                return this.currentPromo.FineValidita.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.FineValidita = `${newValue}T00:00:00Z`;
                if (this.publishDateCustom === false) {
                    this.currentPromo.FinePubblicazione = this.currentPromo.FineValidita;
                }
            }
        },

        inizioPubblicazione: {
            get() {
                if (this.currentPromo.InizioPubblicazione === null) {
                    return this.currentPromo.InizioValidita.substring(0, 10);
                }
                return this.currentPromo.InizioPubblicazione.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.InizioPubblicazione = `${newValue}T00:00:00Z`;
            }
        },

        finePubblicazione: {
            get() {
                if (this.currentPromo.FinePubblicazione === null) {
                    return this.currentPromo.FineValidita.substring(0, 10);
                }
                return this.currentPromo.FinePubblicazione.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.FinePubblicazione = `${newValue}T00:00:00Z`;
            }
        }
    },
    methods: {
        ...mapActions({
            getCategories: promoCategoryActions.PROMOCATEGORY_FETCHCATEGORIES,
            fetchStores: storeActions.STORE_FETCH_STORES,
            updatePromoMulti: multiFarmActions.MULTI_FARM_UPDATEPROMO,
            resetPromos: multiFarmActions.MULTI_FARM_RESET,
            getMultiPromos: multiFarmActions.MULTI_FARM_FETCHPROMOS
        }),
        onResize() {
            this.$nextTick(function() {
                const vw = Math.max(
                    document.documentElement.clientWidth || 0,
                    window.innerWidth || 0
                );
                const vh = Math.max(
                    document.documentElement.clientHeight || 0,
                    window.innerHeight || 0
                );
                this.viewHeight =
                    vh - (this.$vuetify.application.top || 112) - 32;
            });
        },
        isPropertyChanged(property) {
            return this.propertiesChanged.includes(property);
        },

        availableChanged(available) {
            // console.log(
            //     "Disponibili cambiati, siamo filtrati e siamo",
            //     available
            // );
            this.currentAvailable = available;
            // this.availableStores = available;
        },

        appliedChanged(applied) {
            // console.log(
            //     "Applicati cambiati, siamo filtrati e siamo",
            //     applied.length,
            //     applied
            // );
            this.currentSelected = applied;
            // this.currentPromo.PuntiVendita = applied.map(x => x.IDCliente);
            // this.promoStores = applied;
        },

        addAll() {
            this.promoStores = [...this.promoStores, ...this.currentAvailable];
            const ids = this.currentAvailable.map(x => x.IDCliente);
            this.availableStores = this.availableStores.filter(store => {
                return !ids.includes(store.IDCliente);
            });
        },

        removeAll() {
            //console.log('premuto remove all')
            this.availableStores = [
                ...this.availableStores,
                ...this.currentSelected
            ];
            const ids = this.currentSelected.map(x => x.IDCliente);
            this.promoStores = this.promoStores.filter(store => {
                return !ids.includes(store.IDCliente);
            });
        },

        showMultimediaContent(type) {
            switch (type) {
                case 0:
                    this.mediaObject = {
                        type: type,
                        src: this.getAudioFile,
                        name: this.currentPromo.AudioName,
                        poster: {
                            src: this.hasAttachment
                                ? this.getAttachmentFile
                                : this.getImageFile,
                            name: this.hasAttachment
                                ? this.currentPromo.AttachmentName
                                : this.currentPromo.ImageName
                        }
                    };
                    break;

                case 1:
                    this.mediaObject = {
                        type: type,
                        src: this.getVideoFile,
                        name: this.currentPromo.VideoName,
                        poster: null
                    };
                    break;

                case 2:
                    this.mediaObject = {
                        type: type,
                        src: this.getAttachmentFile,
                        name: this.currentPromo.AttachmentName
                    };
                    break;
            }
            this.showContent = true;
        },

        save() {
            this.$confirmdialog
                .open({
                    type: "Warning",
                    title: "Salva Modifiche",
                    message: `Confermi il salvataggio delle modifiche?`,
                    color: "primary"
                })
                .then(async response => {
                    try {
                        this.$confirmdialog.showprogress(true);
                        const status = await this.updatePromoMulti(
                            this.currentPromo
                        );
                        console.log("ok gugol");
                        this.resetPromos();
                        this.$router.go(-1);
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => {
                    console.log("Annullato", err);
                })
                .finally(() => {
                    this.$confirmdialog.showprogress(false);
                    this.$confirmdialog.destroy();
                });
        },

        async revert() {
            this.currentPromo = JSON.parse(JSON.stringify(this.resetModel));
            await this.createStores();
        },

        async createStores() {
            this.stores = [];
            for (let i = 0; i < this.currentPromo.Consorzi.length; i++) {
                // console.log(this.farms[i].IDConsorzio);
                const stores = await this.fetchStores(
                    this.currentPromo.Consorzi[i]
                );
                this.stores = this.stores.concat(stores);
            }
            // console.log(p.length);
            this.availableStores = this.stores.filter(store => {
                return (
                    store.CodiceNazionaleCoopGLN.length > 0 &&
                    !this.currentPromo.PuntiVendita.includes(store.IDCliente)
                );
            });
            this.promoStores = this.stores.filter(x =>
                this.currentPromo.PuntiVendita.includes(x.IDCliente)
            );
            // console.log(
            //     "Negozi Disponibili:",
            //     this.availableStores.length,
            //     this.promoStores
            // );
        }
    },
    async beforeMount() {
        await this.getMultiPromos();
        console.log("leggo dallo state", this.multiFarmPromos);
        const selected = this.multiFarmPromos.filter(
            x => x.IdComunicazioneGenerica === this.promoId
        );
        if (selected.length !== 0) {
            this.currentPromo = JSON.parse(JSON.stringify(selected[0]));
            this.resetModel = JSON.parse(JSON.stringify(this.currentPromo));
        }
        console.log("sono la promo selezionata", this.promoId, this.promo);

        let cats = [];
        for (let i = 0; i < this.currentPromo.Consorzi.length; i++) {
            const result = await this.getCategories(
                this.currentPromo.Consorzi[i]
            );
            console.log(result);
            if (i === 0) cats = result;
            else {
                console.log(cats.length, result.length);
                const common = cats.filter(x =>
                    result.some(y => y.IdCategory === x.IdCategory)
                );
                cats = common;
            }
            // console.log(cats)
        }
        this.localCategories = cats;

        await this.createStores();
    },
    mounted() {
        this.$nextTick(function() {
            this.mounted = true;
        });
    }
};
</script>
<style scoped>
.promoImage {
    height: 575px;
    /*border-style: dotted;
    border-color: green;
    border-width: 4px;*/
}

.promoImg {
    width: 100%;
    object-fit: contain;
    padding: 0;
    margin: 0;
    height: 395px;
}

.changed >>> .v-input__slot {
    background: rgba(255, 0, 0, 0.1);
}
</style>
